import * as React from "react";
import {Link} from "gatsby";
import {AnchorLink} from "gatsby-plugin-anchor-links";
import "../assets/styles/main.css";

const menu = [
  {
    text: "Video",
    id: "#video"
  },
  {
    text: "How to Play",
    id: "#play"
  },
  {
    text: "Placement",
    id: "#placement"
  },
  {
    text: "Contact",
    id: "#contact"
  }
];

export const Layout = ({children}) => {
  return (
    <div className="flex flex-col min-h-screen bg-zinc-900 text-white">
      <header className="w-full py-2">
        <div className="mx-auto">
          <div className="flex content-center items-center flex-wrap lg:flex-no-wrap text-white">
            <div className="flex-1 lg:flex-none order-1 font-bold font-sans inline-block py-1">
              <Link
                to="/"
                title="Big Pineapple"
                className="font-logo font-normal text-4xl flex transition-all ease-in-out duration-300 hover:text-zinc-200 content-center items-center px-4 whitespace-nowrap"
              >
                Big Pineapple
              </Link>
            </div>
            <div className="order-3 md:flex-1 flex flex-wrap md:flex-nowrap justify-end font-sans font-light px-4 text-sm md:text-base">
              {menu.map(item => (
                <AnchorLink
                  key={item.id}
                  to={item.id}
                  className="md:w-auto py-1 md:py-2 pr-4 md:px-2"
                >
                  {item.text}
                </AnchorLink>
              ))}
            </div>
          </div>
        </div>
      </header>
      <main className="flex-grow z-0">{children} </main>{" "}
      <footer className="text-center text-sm py-4 px-8 lg:px-0">
        <div className="container mx-auto">
          All Rights Reserved &copy; Big Pineapple
        </div>
      </footer>{" "}
    </div>
  );
};

export default Layout;
