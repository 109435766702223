import React from "react";
import {StaticQuery, graphql} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";

export const Image = props => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile {
          edges {
            node {
              relativePath
              publicURL
              name
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find(n => {
        const file = n.node.relativePath.replace(/\.[^/.]+$/, "");
        const path = props.filename.replace(/\.[^/.]+$/, "");
        return path.includes(file);
      });
      if (!image) {
        return null;
      }
      var relativePath = image.node.relativePath;
      var ext = relativePath.substring(relativePath.lastIndexOf(".") + 1);
      var imagePath = image.node.publicURL;
      if ("svg" !== ext) {
        imagePath = image.node.childImageSharp.gatsbyImageData;
        return <GatsbyImage image={imagePath} alt={props.alt} {...props} />;
      }
      return <img src={imagePath} alt={props.alt} {...props} />;
    }}
  />
);
