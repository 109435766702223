import React from "react";
import {Image} from "./image";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";

const ImageZoom = props => {
  return (
    <div className={props.wrapperclasses}>
      <Zoom>
        <Image {...props} />
      </Zoom>
    </div>
  );
};

export default ImageZoom;
