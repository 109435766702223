import * as React from "react";
import {useState} from "react";
import {Script} from "gatsby";
import {Helmet} from "react-helmet";
import {Layout} from "../components/layout";
import {StaticImage} from "gatsby-plugin-image";
import ImageZoom from "../components/zoom";
import Video from "../components/video";
import Section from "../components/instructions";

import promoPacket from "../assets/pdf/Big-Pineapple-Promo-Packet.pdf";

import appleTouchIcon from "../assets/images/apple-touch-icon.png";
import favicon32 from "../assets/images/favicon-32x32.png";
import favicon16 from "../assets/images/favicon-16x16.png";

const hands = [
  {
    name: "Royal Flush",
    text:
      "five consecutive cards with the same suit (10, Jack, Queen, King & Ace)"
  },
  {
    name: "Straight Flush",
    text: "five suited and sequential cards"
  },
  {
    name: "Four of a Kind",
    text: "four cards of the same rank"
  },
  {
    name: "Full House",
    text: "three cards of the same rank plus two other cards with the same rank"
  },
  {
    name: "Flush",
    text: "five suited cards"
  },
  {
    name: "Straight",
    text: "five sequential cards of any suit"
  },
  {
    name: "Three of a Kind",
    text: "three cards of the same rank"
  },
  {
    name: "Two Pair",
    text: "two groups of two cards of the same rank"
  },
  {
    name: "One Pair",
    text: "two cards with the same rank"
  },
  {
    name: "High Hand",
    text: "highest cards (no cards match in rank, suit or are sequential)"
  }
];

const bonus = [
  {
    name: "Royal flush",
    text: "500 to 1"
  },
  {
    name: "Straight flush",
    text: "100 to 1"
  },
  {
    name: "Four of a kind",
    text: "20 to 1"
  },
  {
    name: "Full house",
    text: "5 to 1"
  },
  {
    name: "Flush",
    text: "3 to 1"
  },
  {
    name: "Straight",
    text: "2 to 1"
  }
];

const p_bonus = [
  {
    name: "Mini Royal Flush ",
    text: "200 to 1"
  },
  {
    name: "Straight flush",
    text: "50 to 1"
  },
  {
    name: "Trips",
    text: "40 to 1"
  },
  {
    name: "Straight",
    text: "4 to 1"
  },
  {
    name: "Flush",
    text: "3 to 1"
  },
  {
    name: "Pair",
    text: "1 to 1"
  }
];

// markup
const IndexPage = () => {
  const [loaded, setLoaded] = useState(false);
  return (
    <Layout>
      <Helmet>
        <title>Big Pineapple</title>
        <link rel="apple-touch-icon" sizes="180x180" href={appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicon32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicon16} />
      </Helmet>
      <div id="logo-section" className="py-10">
        <div className="container mx-auto text-center">
          <StaticImage
            src="../assets/images/bigpineapple-logo.png"
            alt="Big Pineapple Logo"
            width={200}
          />
        </div>
      </div>
      <div id="video" className="text-black py-4 px-4 lg:px-0">
        <h2 className="text-4xl font-normal text-center max-w-4xl mx-auto mb-6">
          A revolutionary game that will change the way we gamble for years to
          come. <strong>Big Pineapple is here to stay!</strong>
        </h2>
        <div className="container mx-auto py-5 max-w-4xl">
          <Video
            videoSrcURL="https://www.youtube-nocookie.com/embed/1VPQ5XUIPrE"
            videoTitle="ULTIMATE TEXAS HOLDEM BUT BETTER BIG PINEAPPLE"
          />
        </div>
      </div>
      <div id="play" className="text-black py-10 px-8 lg:px-0">
        <div className="max-w-2xl mx-auto">
          <h2 className="text-4xl text-center uppercase font-black tracking-tight">
            How to Play
          </h2>
          <div className="container mx-auto py-10 max-w-4xl">
            <ImageZoom
              filename="betting-area.jpg"
              alt="Big Pineapple Betting Circle"
              width={400}
              layout="constrained"
              className="min-w-[250px]"
              wrapperclasses="md:float-left md:mr-10 md:mb-5"
            />
            <Section title="Introduction">
              <p>
                Big Pineapple brings the tropics to the poker table with this
                Hold’Em poker variant. The object of the game is to make the
                best five-card poker hand using only two of their initial
                three-cards and the five community cards. Big Pineapple uses a
                standard 52-card deck and features head-to-head play against the
                dealer.{" "}
              </p>
            </Section>

            <Section title="How to Play">
              <p>
                To begin the game, the player must make their initial, mandatory
                wagers on both the Ante and Blind betting circles. At the same
                time, the player has a choice to make an optional Straight or
                Pineapple Bonus side bet. The bonus wagers cannot be played
                alone. The dealer will deal three cards face down to each
                player. Players will review their three-card hand and decide
                whether to check or bet. If the player checks, they will go on
                to the next round with their three-card hand. If the player
                decides to bet on their hand, they must discard a card. The
                player will choose one card, from their initial three-card hand,
                to place in the discard box. They will no longer be able to use
                their selected discard going forward. The player will place the
                other two cards face down on the Pineapple betting circle with
                their 4x bet on top of them. Once the bet has been made, the
                player will have no more decisions to make and will await an
                outcome.
              </p>
            </Section>

            <Section title="Rounds">
              <p>
                Players have three rounds to decide whether to check or bet. The
                first round is when players receive their three-card hands. The
                second round, the Flop is revealed, and these community cards
                can be used by everyone. Players can check or wager 2x their
                Ante. At this point, players must discard a card no matter what.
                The third round, the Turn and River cards are revealed. If
                betting, players can wager 1x their Ante. Any player who has not
                yet raised, must do so now or be forced to fold, forfeiting
                their cards, and losing both their Ante and Blind wagers.
              </p>
            </Section>

            <Section title="How to Win">
              <ImageZoom
                filename="table.png"
                alt="Big Pineapple Felt"
                width={400}
                layout="constrained"
                className="min-w-[300px] !max-w-full"
                wrapperclasses="md:float-right md:ml-10 md:mb-10"
              />
              <p>
                The players and dealer are trying to make their best 5-card
                poker hand based on their two-hole cards and five community
                cards on the board. The person with the highest poker ranking
                wins. The dealer will turn over their cards first and any cards
                not used will be turned sideways. The dealer will turn over the
                player’s cards and determine who has the better poker ranking.
                If the player wins, the wagers will be paid according to the
                posted paytable. If the dealer wins, the Ante, Blind and
                Pineapple bet lose. In case of a tie, the Ante, Blind and
                Pineapple bet will push.
              </p>
            </Section>

            <Section title="Ranking of Hands">
              <p>Hands are ranked from highest to lowest:</p>
              <table className="text-sm mt-4 table-auto border-collapse">
                <tbody>
                  {hands.map(item => {
                    return (
                      <tr key={item.name}>
                        <td className="font-bold pr-4">{item.name}</td>
                        <td>{item.text}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Section>

            <Section title="Straight Bonus">
              <p>
                If the player makes an optional Straight Bonus side bet, their
                wager will be paid if they can make a straight or higher using
                two of their original three cards, including the discard. This
                wager can still win even if the Ante and Blind lost.
              </p>

              <h4 className="mt-4">Straight Bonus</h4>
              <table className="text-sm mt-4 table-auto border-collapse">
                <tbody>
                  {bonus.map(item => {
                    return (
                      <tr key={item.name}>
                        <td className="font-bold pr-4">{item.name}</td>
                        <td>{item.text}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Section>

            <Section title="Pineapple Bonus">
              <p>
                If the player makes the Pineapple Bonus side bet, they will win
                if they have a pair or better on their initial three cards. No
                community cards will be used for this bet.
              </p>

              <h4 className="mt-4">Pineapple Bonus</h4>
              <table className="text-sm mt-4 table-auto border-collapse">
                <tbody>
                  {p_bonus.map(item => {
                    return (
                      <tr key={item.name}>
                        <td className="font-bold pr-4">{item.name}</td>
                        <td>{item.text}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </Section>
          </div>
        </div>
      </div>
      <div id="placement" className="text-center text-black py-20 px-2 lg:px-0">
        <h2 className="text-4xl font-normal max-w-4xl mx-auto mb-6">
          <strong>A new table game experience.</strong> More opportunities, and
          higher payouts! Every decision you make counts.
        </h2>
      </div>
      <div id="packet" className="text-black lg:px-12 px-8 lg:px-0">
        <div className="container mx-auto p-5 max-w-4xl bg-white/80 rounded drop-shadow-lg mb-10">
          <h2 className="text-xl font-bold text-center">Promotional Packet</h2>
          <div className="columns-1 md:columns-2 gap-8 mt-6">
            <StaticImage
              src="../assets/images/promopkt_1.png"
              alt="Big Pineapple Promo Packet"
              className="rounded mb-6 lg:mb-0"
              width={1920}
            />
            <div>
              <p>
                Our game welcomes all players, whether experienced or new to
                casino table games. Big Pineapple will bring your inner kid out
                and bring a refreshing dynamic to table games. With a strong
                familiarity to popular table games and poker, we bring the
                Tropics to the casinos!
              </p>
              <p>
                <a
                  href={promoPacket}
                  className="mt-6 inline-block py-2 px-4 rounded bg-light-green text-white"
                >
                  Download
                </a>
              </p>
            </div>
          </div>
        </div>
        <div id="contact" className="pb-10">
          <div className="container mx-auto py-10 max-w-xl">
            <h2 className="text-2xl font-bold text-center mb-6">Contact</h2>
            <Script
              src="https://contact.vegas-aces.com/send.js"
              onLoad={() => setLoaded(true)}
              strategy="idle"
            />
            {loaded && (
              <Script
                id="load_contact_form"
                onLoad={() => console.log("success2")}
              >{`const send = new SendFerris('contactBigPineapple');`}</Script>
            )}

            <form
              id="contactBigPineapple"
              method="POST"
              action=""
            >
              <input
                type="hidden"
                name="source"
                defaultValue=""
              />
              <label>
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                  className="rounded py-1 px-3 w-full mb-5"
                />
              </label>
              <p style={{display: "none"}}>
                <label htmlFor="email_confirmation">Email Confirmation</label>
                <br />
                <input type="text" name="email_confirmation" defaultValue="" />
              </p>
              <label>
                <input
                  type="text"
                  placeholder="Name"
                  name="name"
                  className="rounded py-1 px-3 w-full mb-5"
                />
              </label>
              <label>
                <input
                  type="text"
                  placeholder="Subject"
                  name="subject"
                  className="rounded py-1 px-3 w-full mb-5"
                />
              </label>
              <textarea
                className="rounded py-1 px-3 w-full text-black"
                placeholder="Your Message"
                name="message"
              ></textarea>
              <input
                className="mt-6 inline-block py-2 px-4 rounded bg-light-green text-white cursor-pointer"
                type="submit"
                value="Send"
                id="submit_contact"
                disabled="true"
              />
              <div id="fc_response" className="message"></div>
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
