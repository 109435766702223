import React from "react";

export const Section = ({...props}) => {
  return (
    <div className="section border-b-4 border-pale-yellow py-4">
      <h3 className="mb-2 text-xl text-light-green">{props.title}</h3>
      {props.children}
    </div>
  );
};
export default Section;
